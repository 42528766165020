import { Component, OnInit, ViewChild } from '@angular/core';
import { JarwisService } from '../../services/jarwis.service';
import { TokenService } from '../../services/token.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SnotifyService } from 'ng-snotify';


import {NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import {Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, merge} from 'rxjs/operators';

import {RequestService}  from '../../models/requestService'

@Component({
  selector: 'app-addListing',
  templateUrl: './addListing.html',
  styleUrls: ['./addListing.scss']
})
export class AddListingComponent implements OnInit {
  my_avatar = TokenService.myAvatar();

  @ViewChild('instance') instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  
  
  categories = [];
  category_text = [];

  category = '';
  title = '';
  tags = '';
  description = '';
  
  constructor(
    private Jarwis: JarwisService,
    private Token: TokenService,
    private router: Router,
    private Auth: AuthService,
    private Notify:SnotifyService
  ) { }

  ngOnInit() {
    this.initCategories();
  }

  initCategories() {
    const me = this;
    this.Jarwis.getCategoryList().subscribe(
      data => {
        const res:any = data; 
        me.categories = res;
        for (let i = 0; i < me.categories.length; i++) {
          me.category_text.push(me.categories[i].name);
        }

      },
      error => {console.log('', error); if(error.status = 401) me.Auth.logout(); else alert(error.message);}
    );
  }
 
  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;
    
    return Observable.merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.category_text
        : this.category_text.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }

  submit() {
    const me = this;
    if (me.category == '' || me.title == '' || me.description == '' || me.tags == '') return;
    
    const params = {
      'category_text': me.category,
      'title': me.title,
      'tags': me.tags,
      'description': me.description
    };
    
    this.Jarwis.addListing(params).subscribe(
      data => {
        this.Notify.success('Add List Successfully.', {position: 'rightTop'});
        me.category = '';
        me.title = '';
        me.description = '';
        me.tags = '';
      },
      error => {console.log('error', error);}
    );

  }

}
