import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class TokenService {
  private iss = {
    login: environment.base_url + '/login',
    signup: environment.base_url + '/signup'
  };

  static auth_user = null;

  constructor() { }

  handle(token) {
    this.set(token);
  }

  set(token) {
    localStorage.setItem('token', token);
  }
  get() {
    return localStorage.getItem('token');
  }

  remove() {
    localStorage.removeItem('token');
  }

  isValid() {
    const token = this.get();
    if (token) {
      return true;
      // const payload = this.payload(token);
      // if (payload) {
      //   return Object.values(this.iss).indexOf(payload.iss) > -1 ? true : false;
      // }
    }
    return false;
  }

  payload(token) {
    const payload = token.split('.')[1];
    return this.decode(payload);
  }

  decode(payload) {
    return JSON.parse(atob(payload));
  }

  loggedIn() {
    return this.isValid();
  }

  static setAuthUser(user) {
    this.auth_user = user;
  }

  static getAuthUser() {
    return this.auth_user;
  }

  static myAvatar() {
    if (this.auth_user == null) return "";
    return this.auth_user.avatar;
  }
}
