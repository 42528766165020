import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { JarwisService } from '../../services/jarwis.service';
import { TokenService } from '../../services/token.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-home',
  templateUrl: './home.html',
  styleUrls: ['./home.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('editTimes') editTimes: any;

  search_text = "";
  categories = [];
  requests = [];
  suggestUsers = [];
  address = 'Location';

  edit_location = false;

  constructor(
    private Jarwis: JarwisService,
    private Token: TokenService,
    private router: Router,
    private Auth: AuthService,
    private Notify:SnotifyService,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.initCategories();
    this.initRequests();
    this.initSuggestUsers();
    this.getIpInfo();
  }

  getIpInfo() {
    const me = this;
    this.Jarwis.getIpInfo().subscribe(
      data => {
        const res:any = data; 
        me.address = res.address;
        localStorage.setItem('lon', res.lon);
        localStorage.setItem('lat', res.lat);
        localStorage.setItem('address', res.address);
      },
      error => {console.log('', error); if(error.status = 401) me.Auth.logout(); else alert(error.message);}
    );
  }

  initCategories() {
    const me = this;
    this.Jarwis.getCategoryList().subscribe(
      data => {const res:any = data; me.categories = res;},
      error => {console.log('', error); if(error.status = 401) me.Auth.logout(); else alert(error.message);}
    );
  }
  initRequests() {
    const me = this;
    this.Jarwis.getRequestList().subscribe(
      data => {const res:any = data; me.requests = res;},
      error => {console.log('', error); if(error.status = 401) me.Auth.logout(); else alert(error.message);}
    );
  }
  initSuggestUsers() {
    const me = this;
    this.Jarwis.getSuggestUserList().subscribe(
      data => {const res:any = data; me.suggestUsers = res;},
      error => {console.log('', error); if(error.status = 401) me.Auth.logout(); else alert(error.message);}
    );
  }

  submitSearch(event) {
    const me = this;
    if(event.keyCode == 13) {
      // if(me.search_text == "") return;
      localStorage.setItem('search_text', me.search_text);
      this.router.navigateByUrl('/nearby/search');
    }
  }

  getAddress(place) {
    const me = this;
    if(place.geometry) {
      this.address = place['formatted_address'];
      localStorage.setItem('lon', place.geometry.location.lng());
      localStorage.setItem('lat', place.geometry.location.lat());
      localStorage.setItem('address', place['formatted_address']);
      setTimeout(() => { document.getElementById('clsEdit').dispatchEvent(new Event('click'));});
    } else {
      this.Notify.error('Invalid Address', {position: 'rightTop'});
      return;
    }
  }
}
