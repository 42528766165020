import { Component, OnInit, ViewChild } from '@angular/core';
import { JarwisService } from '../../services/jarwis.service';
import { TokenService } from '../../services/token.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SnotifyService } from 'ng-snotify';

import {NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import {Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, merge} from 'rxjs/operators';

import {RequestService}  from '../../models/requestService'

@Component({
  selector: 'app-profile',
  templateUrl: './profile.html',
  styleUrls: ['./profile.scss']
})
export class ProfileComponent implements OnInit {
  update_profile = false;

  my_avatar = TokenService.myAvatar();

  update_user;
  
  @ViewChild('instance') instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  
  
  categories = [];
  category_text = [];

  category = '';
  title = '';
  tags = '';
  description = '';

  user = null;
  
  constructor(
    private Jarwis: JarwisService,
    private Token: TokenService,
    private router: Router,
    private Auth: AuthService,
    private Notify:SnotifyService
  ) { }

  ngOnInit() {
    this.getProfile();
  }

  getProfile() {
    const me = this;
    this.Jarwis.getProfile().subscribe(
      data => {
        const res:any = data; 
        me.user = data;
        console.log('tag', me.user);
      },
      error => {console.log('', error); if(error.status = 401) me.Auth.logout(); else alert(error.message);}
    );
  }

  showUpdate() {
    this.update_profile = true;
    this.update_user = JSON.parse(JSON.stringify(this.user));
  }

  getAddress(place) {
    if(place.geometry) {
      this.update_user.address = place['formatted_address'];
      this.update_user.longitude = place.geometry.location.lng();
      this.update_user.latitude = place.geometry.location.lat();
    } else {
      this.update_user.longitude = 0;
      this.update_user.latitude = 0;
    }
  }


}
