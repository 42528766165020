import { Component, OnInit, ViewChild } from '@angular/core';
import { JarwisService } from '../../services/jarwis.service';
import { TokenService } from '../../services/token.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SnotifyService } from 'ng-snotify';
import { RequestService }  from '../../models/requestService'

@Component({
  selector: 'app-nearby',
  templateUrl: './nearby.html',
  styleUrls: ['./nearby.scss']
})
export class NearbyComponent implements OnInit {
  suggestUsers = [];
  
  page_type = 'search';
  page_param = '';

  show_type = 'list';
  sel_member;
  
  constructor(
    private Jarwis: JarwisService,
    private Token: TokenService,
    private router: Router,
    private route: ActivatedRoute,
    private Auth: AuthService,
    private Notify:SnotifyService
  ) { }

  ngOnInit() {
    const me = this;
    this.route.params.subscribe(params => {
      console.log('params', params);
      if(params['type'] != undefined) me.page_type = params['type'];
      if(params['param'] != undefined) {
        me.page_param = params['param'];
      }
   });

    this.initSuggestUsers();
  }


  initSuggestUsers() {
    const me = this;
    this.Jarwis.getSuggestUserList().subscribe(
      data => {const res:any = data; me.suggestUsers = res;},
      error => {console.log('', error); if(error.status = 401) me.Auth.logout(); else alert(error.message);}
    );
  }

  updateShowType(str) {
    this.show_type = str;
  }
  
  showMember(user) {
    this.show_type = 'member';
    this.sel_member = user;
    console.log('tag', user);
  }
}
