import { Component, OnInit, ViewChild } from '@angular/core';
import { JarwisService } from '../../services/jarwis.service';
import { TokenService } from '../../services/token.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SnotifyService } from 'ng-snotify';
import { RequestService }  from '../../models/requestService'

@Component({
  selector: 'app-listings',
  templateUrl: './listings.html',
  styleUrls: ['./listings.scss']
})
export class ListingsComponent implements OnInit {
  
  listings = [];
  
  constructor(
    private Jarwis: JarwisService,
    private Token: TokenService,
    private router: Router,
    private Auth: AuthService,
    private Notify:SnotifyService
  ) { }

  ngOnInit() {
    this.initlistings();
  }

  initlistings() {
    const me = this;
    this.Jarwis.getListings().subscribe(
      data => {const res:any = data; me.listings = res;},
      error => {console.log('', error); if(error.status = 401) me.Auth.logout(); else alert(error.message);}
    );
  }

 

}
