import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { $ } from 'protractor';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  constructor(private translate: TranslateService) {
    translate.setDefaultLang('en');
  }
  changeLang(lang: string) {
    this.translate.use(lang);
    if(lang == 'en') {
      document.getElementsByTagName('body')[0].classList.remove('ln-urdu');
    } else {
      document.getElementsByTagName('body')[0].classList.add('ln-urdu');
    }
  }
}
