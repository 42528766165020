import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BeforeLoginService } from './services/before-login.service';
import { AfterLoginService } from './services/after-login.service';

import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { ProfileComponent } from './components/profile/profile.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { RequestResetComponent } from './components/password/request-reset/request-reset.component';
import { ResponseResetComponent } from './components/password/response-reset/response-reset.component';
import { HomeComponent } from './components/home/home.component';
import { RequestComponent } from './components/requestService/requestService.component';
import { BrowseRequestsComponent } from './components/browseRequests/browseRequests.component';
import { AddListingComponent } from './components/addListing/addListing.component';
import { ListingsComponent } from './components/listings/listings.component';
import { NearbyComponent } from './components/nearby/nearby.component'

const appRoutes: Routes = [

  // { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "", component: HomeComponent },
  { path: "request-service", component: RequestComponent, canActivate: [AfterLoginService]},
  { path: "browse-requests", component: BrowseRequestsComponent},
  { path: "browse-requests/:id", component: BrowseRequestsComponent},
  { path: "add-listing", component: AddListingComponent},
  { path: "listings", component: ListingsComponent},
  
  { path: "nearby", component: NearbyComponent},
  { path: "nearby/:type", component: NearbyComponent},
  { path: "nearby/:type/:param", component: NearbyComponent},

  { path: 'login', component: LoginComponent, canActivate: [BeforeLoginService] },
  { path: 'signup', component: SignupComponent, canActivate: [BeforeLoginService] },
  { path: 'profile', component: ProfileComponent, canActivate: [AfterLoginService] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AfterLoginService] },
  { path: 'request-password-reset', component: RequestResetComponent, canActivate: [BeforeLoginService] },
  { path: 'response-password-reset', component: ResponseResetComponent, canActivate: [BeforeLoginService] },

];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
