import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import {HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { Select2Module } from 'ng2-select2';

import { AppRoutingModule } from './/app-routing.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { JarwisService } from './services/jarwis.service';
import { TokenService } from './services/token.service';
import { AuthService } from './services/auth.service';
import { TaskService } from './services/task.service';
import { AfterLoginService } from './services/after-login.service';
import { BeforeLoginService } from './services/before-login.service';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';


import { TaskListModalComponent } from './components/dashboard/modal/taskListModal/taskListModal.component'
import { TaskModalComponent } from './components/dashboard/modal/taskModal/taskModal.component'


import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { ProfileComponent } from './components/profile/profile.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { RequestResetComponent } from './components/password/request-reset/request-reset.component';
import { ResponseResetComponent } from './components/password/response-reset/response-reset.component';
import { HomeComponent } from './components/home/home.component';
import { RequestComponent } from "./components/requestService/requestService.component";
import { BrowseRequestsComponent } from './components/browseRequests/browseRequests.component';
import { AddListingComponent } from './components/addListing/addListing.component';
import { ListingsComponent } from './components/listings/listings.component';
import { AutocompleteComponent } from './components/googleAutoComplete/google-places.component'
import { NearbyComponent } from './components/nearby/nearby.component'

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoginComponent,
    SignupComponent,
    ProfileComponent,
    DashboardComponent,
    RequestResetComponent,
    ResponseResetComponent,
    HomeComponent,
    RequestComponent,
    BrowseRequestsComponent,
    AddListingComponent,
    ListingsComponent,
    NearbyComponent,
    AutocompleteComponent,

    TaskListModalComponent,
    TaskModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    SnotifyModule,
    Select2Module,

    NgbModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ],
  exports: [
      TranslateModule
  ],
  providers: [
    JarwisService, 
    TokenService, 
    AuthService, 
    TaskService, 
    AfterLoginService, 
    BeforeLoginService,
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
    SnotifyService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    TaskListModalComponent,
    TaskModalComponent
  ]
})
export class AppModule { }
