import { Component, OnInit, ViewChild } from '@angular/core';
import { JarwisService } from '../../services/jarwis.service';
import { TokenService } from '../../services/token.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SnotifyService } from 'ng-snotify';
import { RequestService }  from '../../models/requestService'

@Component({
  selector: 'app-browseRequests',
  templateUrl: './browseRequests.html',
  styleUrls: ['./browseRequests.scss']
})
export class BrowseRequestsComponent implements OnInit {
  sel_category = '';
  categories = [];
  requests = [];
  requestService:RequestService = new RequestService();
  
  
  constructor(
    private Jarwis: JarwisService,
    private Token: TokenService,
    private router: Router,
    private route: ActivatedRoute,
    private Auth: AuthService,
    private Notify:SnotifyService
  ) { }

  ngOnInit() {
    const me = this;
    this.route.params.subscribe(params => {
      if(params['id'] != undefined) me.sel_category = params['id'];
   });

    this.initRequests();
    this.initCategories();
  }

  initCategories() {
    const me = this;
    this.Jarwis.getCategoryList().subscribe(
      data => {const res:any = data; me.categories = res;},
      error => {console.log('', error); if(error.status = 401) me.Auth.logout(); else alert(error.message);}
    );
  }

  initRequests() {
    const me = this;
    this.Jarwis.getRequestList().subscribe(
      data => {const res:any = data; me.requests = res;},
      error => {console.log('', error); if(error.status = 401) me.Auth.logout(); else alert(error.message);}
    );
  }
  
  
  get searchedReqeust() {
    const me = this;
    const res_reqeusts = [];
    
    for (let i = 0; i < this.requests.length; i++) {
      const request = this.requests[i];
      if(me.sel_category != '') {
         if (me.sel_category == request.category.id) {
           res_reqeusts.push(request);   
         }
      } else {
        res_reqeusts.push(request);
      }
    }
    

    return res_reqeusts;
  }
  
 

}
